import React from "react";
import "../Components/AboutHeader/AboutHeader";
import AboutHeader from "../Components/AboutHeader/AboutHeader";
import AboutContent from "../Components/AboutContent/AboutContent";
import Developers from "../Components/Developers/Developers";

const About = () => {
  return (
    <div>
      <AboutHeader />
      <AboutContent />
      <Developers />
    </div>
  );
};

export default About;
