import ProjectListItem from "./ProjectListItem";

function ProjectList({ projectData, isFiltering }) {
  if ((!projectData || projectData.length === 0) && isFiltering)
    return (
      <p className="">
        No results found, please search other keywords or less filters.
      </p>
    );

  return (
    <div className="md:grid md:grid-cols-3 w-[90vw] mx-auto md:gap-12 flex flex-col gap-8">
      {projectData.map((project) => (
        <div key={project._id} className="flex place-content-around">
          <ProjectListItem
            key={project._id}
            projectId={project._id}
            title={project.title}
            summary={project.projectSummary}
            tags={project.tags}
          />
        </div>
      ))}
    </div>
  );
}

export default ProjectList;
