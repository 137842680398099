import { useSearchParams } from "react-router-dom";
import useGetFilterFields from "../hooks/useGetFilterFields";
import { IoCloseCircle } from "react-icons/io5";
import MultipleChoiceComboBox from "./MultipleChoiceComboBox";
import ComboBox from "./ComboBox";
import { CiFilter } from "react-icons/ci";

function FilteredItems() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentFilterObj = JSON.parse(searchParams.get("filter"));
  if (!currentFilterObj) return null;
  let currentFilter = Object.values(currentFilterObj);
  currentFilter = currentFilter.flat();

  const closeHandler = (item) => {
    const newSearchParams = new URLSearchParams(searchParams);
    const filters = JSON.parse(newSearchParams.get("filter"));

    const updatedFilters = Object.keys(filters).reduce((acc, key) => {
      if (key === "Tags") {
        acc[key] = filters[key].filter((tag) => tag !== item);
        if (acc[key].length === 0) {
          delete acc[key];
        }
        return acc;
      }
      if (filters[key] !== item) {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    if (Object.keys(updatedFilters).length === 0) {
      newSearchParams.delete("filter");
    } else {
      newSearchParams.set("filter", JSON.stringify(updatedFilters));
    }
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex w-[90vw] mx-auto gap-2 flex-wrap">
      {currentFilter.map((item) => (
        <div
          className="bg-stone-200 px-2 py-1 text-sm flex flex-row rounded-full"
          key={item}
        >
          <p className="ml-1">{item}</p>
          <div
            className="flex items-center justify-center ml-1 hover:cursor-pointer"
            onClick={() => closeHandler(item)}
          >
            <IoCloseCircle size={16} className="text-stone-500" />
          </div>
        </div>
      ))}
    </div>
  );
}

function ProjectFilter() {
  const { filterData, isLoadingFilterData } = useGetFilterFields();

  const parsedData = {
    partner: [],
    tags: [],
    semester: [],
    instructor: [],
  };

  if (!isLoadingFilterData) {
    filterData.forEach((item) => {
      parsedData.partner = [
        ...new Set([...parsedData.partner, ...(item.partners ?? [])]),
      ];
      parsedData.tags = [
        ...new Set([...parsedData.tags, ...(item.tags ?? [])]),
      ];
      parsedData.semester = [
        ...new Set([...parsedData.semester, ...(item.semesters ?? [])]),
      ];
      parsedData.instructor = [
        ...new Set([...parsedData.instructor, ...(item.instructors ?? [])]),
      ];
    });
  }

  const filterTypes = ["Partner", "Instructor", "Semester"];

  Object.keys(parsedData).forEach((key) => {
    parsedData[key] = parsedData[key].filter((item) => item !== "");
  });

  return (
    <div className="mb-8">
      <div className="flex flex-wrap gap-2 md:gap-4 mb-4 w-[90vw] mx-auto justify-center md:justify-start">
        <div className="items-center justify-center -mr-1 hidden md:flex">
          <CiFilter size={32} />
        </div>
        {filterTypes.map((type) => (
          <ComboBox
            key={type}
            data={parsedData[type.toLowerCase()]}
            type={type.toLowerCase()}
          />
        ))}
        <MultipleChoiceComboBox data={parsedData["tags"]} type={"Tags"} />
      </div>
      <FilteredItems />
    </div>
  );
}

export default ProjectFilter;
