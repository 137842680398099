import { useQuery } from "@tanstack/react-query";

export default function useGetHomepageInfo() {
  const { data: homepageInfo, isLoading: isLoadingHomepageInfo } = useQuery({
    queryKey: ["homepageInfo"],
    queryFn: async () => {
      const res = await fetch("/api/homepageInfo/route");
      const data = await res.json();
      return data;
    },
  });
  return { homepageInfo, isLoadingHomepageInfo };
}
