import { useSearchParams } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./ui/pagination";
import { Tooltip } from "@nextui-org/tooltip";

const NUM_PAGINATION = 2;

function ProjectListPagination({ count, pageSize }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") ?? 1);
  const lastPageNum = Math.ceil(count / pageSize);
  return (
    <Pagination className="pb-12 pt-4">
      <PaginationContent>
        <Tooltip content={currentPage <= 1 ? "No previous page" : ""}>
          <div
            className={`${
              currentPage <= 1 ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => {
              if (currentPage <= 1) return;
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("page", currentPage - 1);
              setSearchParams(newSearchParams);
            }}
          >
            <PaginationItem>
              <PaginationPrevious />
            </PaginationItem>
          </div>
        </Tooltip>
        {currentPage - NUM_PAGINATION > 1 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {Array.from({ length: lastPageNum })
          .map((_, index) => index + 1)
          .map((pageNum, ind) => {
            if (pageNum > currentPage + NUM_PAGINATION) return null;
            if (pageNum < currentPage - NUM_PAGINATION) return null;
            return (
              <div
                key={pageNum}
                onClick={() => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set("page", pageNum);
                  setSearchParams(newSearchParams);
                }}
                className="cursor-pointer"
              >
                <PaginationItem key={pageNum}>
                  <PaginationLink isActive={currentPage === pageNum}>
                    {pageNum}
                  </PaginationLink>
                </PaginationItem>
              </div>
            );
          })}
        {currentPage + NUM_PAGINATION < lastPageNum && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        <Tooltip content={currentPage >= lastPageNum ? "No more pages" : ""}>
          <div
            className={`${
              currentPage >= lastPageNum
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
            onClick={() => {
              if (currentPage >= lastPageNum) return;
              const newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("page", currentPage + 1);
              setSearchParams(newSearchParams);
            }}
          >
            <PaginationItem>
              <PaginationNext />
            </PaginationItem>
          </div>
        </Tooltip>
      </PaginationContent>
    </Pagination>
  );
}

export default ProjectListPagination;
