import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "../lib/helper";

function ComboBox({ data, type }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const labelValueData = data.map((item) => ({
    value: item,
    label: item,
  }));

  const value = searchParams.has("filter")
    ? JSON.parse(searchParams.get("filter"))[type]
    : "";

  const selectHandler = (currentValue) => {
    const newSearchParams = new URLSearchParams(searchParams);
    let filters = {};

    // Parse the existing filter parameter if it exists
    if (newSearchParams.has("filter")) {
      filters = JSON.parse(newSearchParams.get("filter"));
    }

    // Update the filter value or remove it if it matches the current value
    if (currentValue === value) {
      delete filters[type];
    } else {
      filters[type] = currentValue;
    }

    // Update the search parameters with the new filter state
    if (Object.keys(filters).length === 0) {
      newSearchParams.delete("filter");
    } else {
      newSearchParams.set("filter", JSON.stringify(filters));
    }
    newSearchParams.set("page", "1");
    setOpen(false);
    setSearchParams(newSearchParams);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[48%] md:max-w-[12rem] md:min-w-[10rem] justify-between text-stone-800 border-1 border-stone-400"
        >
          <p className="text-base">{capitalize(type)}</p>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[15rem] p-0">
        <Command>
          <CommandInput placeholder={`Search ${type}...`} />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {labelValueData.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={selectHandler}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default ComboBox;
