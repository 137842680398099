import { useState } from "react";
import { LuMenu } from "react-icons/lu";
import SearchBar from "./SearchBar";
import { IoClose } from "react-icons/io5";
import useOutsideClick from "../hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";

function NavBarMenuMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOutsideClick(() => setIsOpen(false), true);
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen((pre) => !pre);
  const closeMobileMenu = () => setIsOpen(false);
  const menuItems = [
    { title: "Home", link: "/" },
    { title: "Projects", link: "/projects" },
    { title: "About", link: "/about" },
  ];

  const navigateHandler = (link) => {
    navigate(link);
    setIsOpen(false);
  };

  return (
    <div className="md:hidden mr-4" ref={ref}>
      <div onClick={toggleMenu}>
        {!isOpen && <LuMenu color="white" size={28} />}
        {isOpen && <IoClose color="white" size={28} />}
      </div>
      {isOpen && (
        <div className="absolute left-0 top-full bg-black shadow-lg w-full z-30 py-4 h-auto">
          <div className="bg-black w-3/4 mx-auto">
            <SearchBar closeMobileMenu={closeMobileMenu} />
          </div>
          <ul className="text-white mt-4 flex flex-col gap-4">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="pb-2 border-b-1 border-stone-100 w-[90%] mx-auto last:border-none"
                onClick={() => navigateHandler(item.link)}
              >
                <p className="text-xl">{item.title}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NavBarMenuMobile;
