import { IoPricetagSharp } from "react-icons/io5";

function ProjectItemTag({ tagName }) {
  return (
    <div className="flex items-center gap-1 md:gap-2 border-2 rounded-full px-1 border-slate-400 cursor-pointer py-0.5 md:px-2 md:py-1">
      <IoPricetagSharp className="size-3 md:size-4" />
      <p className="text-xs">{tagName}</p>
    </div>
  );
}

export default ProjectItemTag;
