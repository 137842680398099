import { useNavigate } from "react-router-dom";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import useGetHomepageInfo from "../hooks/useGetHomepageInfo";

function PreviewProjectsMobile() {
  const navigate = useNavigate();
  const { homepageInfo } = useGetHomepageInfo();

  const previewData = homepageInfo?.previewData || [];
  return (
    <div className="w-full mt-8 text-xl sm:hidden">
      <h1>Featured Projects</h1>
      <Carousel className="w-full mt-4 overflow-hidden">
        <CarouselContent className="-ml-4">
          {previewData.map((project, index) => (
            <CarouselItem key={index}>
              <div
                onClick={() => navigate(`/projects/${project._id}`)}
                className="w-full"
              >
                <img
                  src={project.previewImage?.asset.url || "/assets/neu-N.png"}
                  alt={project.previewImage?.alt || "Project Image"}
                  className="w-full object-cover aspect-[16/10]"
                />
                <div className="mt-4">
                  <h1 className="text-lg text-left line-clamp-4 px-4 font-bold">
                    {project.title}
                  </h1>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="" />
        <CarouselNext className="" />
      </Carousel>
    </div>
  );
}

export default PreviewProjectsMobile;
