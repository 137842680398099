import React from "react";
import ListPageLayout from "../Components/ListPageLayout";
import useGetProjects from "../hooks/useGetProjects";
import ProjectListPagination from "../Components/ProjectListPagination";
import { useSearchParams } from "react-router-dom";
import useGetFilterResults from "../hooks/useGetFilterResults";
import ProjectList from "../Components/ProjectList";
import { Spinner } from "@nextui-org/react";
import ProjectFilter from "../Components/ProjectFilter";

const PAGE_SIZE = 6;

const Projects = () => {
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") ?? 1);
  let { projects, isLoadingProjects } = useGetProjects();
  let { filteredResults, isLoadingFilteredResults } = useGetFilterResults({
    filter: JSON.parse(searchParams.get("filter")),
    searchKeywod: searchParams.get("search"),
  });

  let isFiltering =
    Boolean(searchParams.get("filter")) || Boolean(searchParams.get("search"));

  return (
    <div>
      <ListPageLayout title="Browse Projects">
        <ProjectFilter />
        {(isLoadingProjects || isLoadingFilteredResults) && (
          <div className="w-full h-[50vh] flex items-center justify-center">
            <Spinner size="lg" />
            <p className="ml-4">Loading Data...</p>
          </div>
        )}

        {!isLoadingProjects && !isFiltering && (
          <ProjectList
            projectData={projects.slice(
              (currentPage - 1) * PAGE_SIZE,
              currentPage * PAGE_SIZE
            )}
            isFiltering={false}
          />
        )}
        {!isLoadingFilteredResults && isFiltering && (
          <ProjectList
            projectData={filteredResults.slice(
              (currentPage - 1) * PAGE_SIZE,
              currentPage * PAGE_SIZE
            )}
            isFiltering={true}
          />
        )}
      </ListPageLayout>
      {!isFiltering && !isLoadingProjects && projects?.length > 0 && (
        <ProjectListPagination
          count={isLoadingProjects ? 1 : projects.length}
          pageSize={PAGE_SIZE}
        />
      )}
      {isFiltering &&
        !isLoadingFilteredResults &&
        filteredResults?.length > 0 && (
          <ProjectListPagination
            count={isLoadingFilteredResults ? 1 : filteredResults.length}
            pageSize={PAGE_SIZE}
          />
        )}
    </div>
  );
};

export default Projects;
