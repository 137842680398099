export const testimonials = [
  {
    feedback:
      "This service has exceeded our expectations. Highly recommend! This service has exceeded our expectations. Highly recommend! ",
    name: "John Doe",
    image: "path/to/image1.jpg",
    company: "ABC Company",
  },
  {
    feedback: "Outstanding experience, will definitely use again.",
    name: "Jane Smith",
    image: "path/to/image2.jpg",
    company: "XYZ Company",
  },
  {
    feedback: "Excellent customer support and great results.",
    name: "Mike Johnson",
    image: "path/to/image3.jpg",
    company: "123 Company",
  },
];

export const developers = [
  {
    name: "Wenqian Xie",
    imageSrc: "/assets/wenqian.jpeg",
    program: "Master of Computer Science, Align",
    linkedin: "http://www.linkedin.com/in/wenqian-xie",
  },
  {
    name: "Haoning Wang",
    imageSrc: "/assets/haoning.jpeg",
    program: "Master of Computer Science, Align",
    linkedin: "https://www.linkedin.com/in/haoning-w/",
  },
];
