import { useQuery, useQueryClient } from "@tanstack/react-query";

async function getProjectById({ projectId }) {
  const res = await fetch(`/api/projects/${projectId}/route`);
  const data = await res.json();
  return data;
}

export default function useGetProjectById({ projectId }) {
  const queryClient = useQueryClient();
  let projectData;
  const cachedData = queryClient.getQueriesData({ queryKey: ["allProjects"] });

  if (cachedData && cachedData[0]) {
    if (cachedData[0][1]) {
      projectData = cachedData[0][1].find(
        (project) => project._id === projectId
      );
    }
  }

  const { data: project, isLoading: isLoadingProject } = useQuery({
    queryKey: ["singleProjectData", projectId],
    queryFn: projectData
      ? () => projectData
      : () => getProjectById({ projectId }),
  });

  return { project, isLoadingProject };
}
