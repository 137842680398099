import React from "react";
import "./Herosection.css";
import { NavLink } from "react-router-dom";

const Herosection = () => {
  return (
    <div className="herosection">
      <div className="herosection-contents">
        <div className="intro">
          <p className="title">
            Our prime showcase of emerging leaders and innovators.
          </p>
          <div className="bottom-section">
            <NavLink to="/projects">
              <button className="hero-btn hero-btn-main">
                BROWSE PROJECTS
              </button>
            </NavLink>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfMd_HpF0b5FJdnfrDM-uHxrdRMXTNv9YSfWxuSrCAmcT0ezA/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="hero-btn hero-btn-secondary">
                PROPOSE PROJECTS
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
