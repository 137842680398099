import useGetProjects from "./useGetProjects";

export default function useGetFilterResults(condition) {
  const { projects, isLoadingProjects } = useGetProjects();
  const { filter, searchKeywod } = condition;

  if (!filter && !searchKeywod) {
    return {
      filteredResults: projects,
      isLoadingFilteredResults: isLoadingProjects,
    };
  }

  let filteredResults = projects;

  if (filter) {
    filteredResults = projects?.filter((project) => {
      let isMatch = true;
      Object.keys(filter).forEach((key) => {
        if (key === "Tags") {
          filter[key]?.forEach((tag) => {
            if (!project.tags?.includes(tag)) {
              isMatch = false;
            }
          });
        } else if (filter[key]) {
          if (key?.toLowerCase() === "partner") {
            if (!project[key]?.includes(filter[key])) {
              isMatch = false;
            }
          } else if (!project[key + "s"]?.includes(filter[key])) {
            isMatch = false;
          }
        }
      });
      return isMatch;
    });
  }

  // search through every field
  if (searchKeywod) {
    filteredResults = filteredResults?.filter((project) => {
      let isMatch = false;
      Object.keys(project).forEach((key) => {
        if (
          project[key]
            ?.toString()
            .toLowerCase()
            .includes(searchKeywod.toLowerCase())
        ) {
          isMatch = true;
        }
      });
      return isMatch;
    });
  }

  return { filteredResults, isLoadingFilteredResults: isLoadingProjects };
}
