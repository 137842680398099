import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import About from "./Pages/About";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SingleProjectDetails from "./Pages/SingleProjectDetails";
import { NextUIProvider } from "@nextui-org/react";

const queryClient = new QueryClient();

function App() {
  return (
    <NextUIProvider>
      <div className="App">
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/about" element={<About />} />
                <Route
                  path="/projects/:projectId"
                  element={<SingleProjectDetails />}
                />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </React.StrictMode>
      </div>
    </NextUIProvider>
  );
}

export default App;
