import React from "react";
import "./AboutHeader.css";

const AboutHeader = () => {
  return (
    <div className="about-header">
      <div className="hook">
        <p>Looking for in-depth research support ... ?</p>
      </div>
    </div>
  );
};

export default AboutHeader;
