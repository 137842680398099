import React from "react";
import "./Developers.css";
import { developers } from "../../../src/lib/constants";

const Developer = ({ name, imageSrc, program, linkedin }) => (
  <div className="developer-info">
    <img src={imageSrc} alt={name} className="developer-image" />
    <div className="developer-details">
      <div className="developer-name">
        <p>{name}</p>
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/linkedin-logo.webp"
            alt="LinkedIn"
            className="linkedin-logo"
          />
        </a>
      </div>
      <div className="developer-program">
        <p>{program}</p>
      </div>
    </div>
  </div>
);

const Developers = () => {
  return (
    <div className="developers-container">
      <div className="developers-title">
        <p>Developers</p>
        <div className="developers-info-container">
          {developers.map((developer) => (
            <Developer
              key={developer.name}
              name={developer.name}
              imageSrc={developer.imageSrc}
              program={developer.program}
              linkedin={developer.linkedin}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Developers;
