import React from "react";
import "./PreviewProjects.css";
import { useNavigate, NavLink } from "react-router-dom";
import useGetHomepageInfo from "../../hooks/useGetHomepageInfo";

const PreviewProjects = () => {
  const navigate = useNavigate();
  const { homepageInfo } = useGetHomepageInfo();

  const previewData = homepageInfo?.previewData || [];

  return (
    <div className="section-container">
      <h1 className="preview-header font-light">Explore Inspiring Projects</h1>
      <div className="preview-projects">
        {previewData?.slice(0, 6).map((project, index) => (
          <div
            key={index}
            className="project"
            onClick={() => navigate(`/projects/${project._id}`)}
          >
            {project.previewImage && (
              <img
                src={project.previewImage.asset.url}
                alt={project.previewImage.alt || "Project Image"}
              />
            )}
            <div className="project-info">
              <h1 className="project-name line-clamp-2 font-bold leading-7 text-xl mt-2">
                {project.title}
              </h1>
              <p className="project-summary line-clamp-4 leading-7">
                {project.projectSummary}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        <NavLink to="/projects">
          <button>MORE PROJECTS</button>
        </NavLink>
      </div>
    </div>
  );
};

export default PreviewProjects;
