import { useQuery } from "@tanstack/react-query";

async function getProjects() {
  const res = await fetch("/api/projects/route");
  const data = await res.json();
  return data;
}

export default function useGetProjects() {
  const { data: projects, isLoading: isLoadingProjects } = useQuery({
    queryKey: ["allProjects"],
    queryFn: () => getProjects(),
  });

  return { projects, isLoadingProjects };
}
