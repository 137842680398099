import React, { useState } from "react";
import "./Testimonial.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import useGetHomepageInfo from "../../hooks/useGetHomepageInfo";

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { homepageInfo } = useGetHomepageInfo();

  const testimonials = homepageInfo?.testimonialData || [];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div>
        <FontAwesomeIcon icon={faQuoteLeft} className="icon-quote-left" />
      </div>
      <div className="testimonial-container">
        <div className="previous-handler" onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        {testimonials?.length > 0 && (
          <div className="testimonial my-auto mx-5 px-4">
            <div className="quote-container">
              <p className="quote line-clamp-[10] md:line-clamp-4">
                {testimonials[currentIndex].clientTestimonial}
              </p>
            </div>
            <p className="break-line">______</p>
            <br />
            <p className="clientName">
              {testimonials[currentIndex].clientName.toUpperCase()}
            </p>
            <div className="clientTitleAndCompany">
              {testimonials[currentIndex].clientTitle && (
                <p className="clientTitle">
                  {testimonials[currentIndex].clientTitle},
                </p>
              )}
              <p className="clientCompany">
                {testimonials[currentIndex].clientCompany}
              </p>
            </div>
          </div>
        )}
        <div className="next-handler" onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </>
  );
};

export default Testimonial;
