import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

function MultipleChoiceComboBox({ data, type }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const labelValueData = data.map((item) => ({
    value: item,
    label: item,
  }));

  const selectedLength = searchParams.get("filter")
    ? JSON.parse(searchParams.get("filter"))[type]
      ? JSON.parse(searchParams.get("filter"))[type].length
      : 0
    : 0;

  const value = searchParams.has("filter")
    ? JSON.parse(searchParams.get("filter"))[type]
    : "";

  const selectHandler = (currentValue) => {
    const currentFilter = searchParams.has("filter")
      ? JSON.parse(searchParams.get("filter"))
      : { Tags: [] };

    if (!currentFilter.Tags) {
      currentFilter.Tags = [];
    }

    let updatedTags;
    if (currentFilter.Tags.includes(currentValue)) {
      updatedTags = currentFilter.Tags.filter((tag) => tag !== currentValue);
    } else {
      updatedTags = [...currentFilter.Tags, currentValue];
    }

    const newFilter = { ...currentFilter, Tags: updatedTags };

    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("filter", JSON.stringify(newFilter));
      return prev;
    });
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[48%] md:min-w-[10rem] md:max-w-[12rem] justify-between text-stone-800 border-1 border-stone-400"
        >
          {
            <div className="flex">
              <p className="text-base">{type}</p>
              {selectedLength > 0 && (
                <div className="flex items-center justify-center bg-stone-300 ml-6 w-5 h-5 rounded-full my-auto">
                  <p className="text-xs">{selectedLength}</p>
                </div>
              )}
            </div>
          }
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[15rem] p-0">
        <Command>
          <CommandInput placeholder={`Search ${type?.toLowerCase()}...`} />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {labelValueData.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={selectHandler}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value?.includes(item.value) ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default MultipleChoiceComboBox;
