import { useNavigate } from "react-router-dom";
import ProjectItemTag from "./ProjectItemTag";

function ProjectListItem({ title, summary, tags, projectId }) {
  const navigate = useNavigate();
  return (
    <div
      className="border-slate-700 border-0 p-6 md:p-8 rounded-2xl shadow-outer hover:cursor-pointer hover:shadow-stone-600 transition-all duration-300 hover:-translate-y-1"
      onClick={() => navigate(`/projects/${projectId}`)}
    >
      <p className="text-start text-xl md:text-[28px] md:leading-9 text-[#d71827] font-medium line-clamp-3">
        {title}
      </p>
      <div className="md:h-60">
        <h1 className="text-start mt-2 pt-2 line-clamp-6 md:line-clamp-[9]">
          {summary}
        </h1>
      </div>
      <div className="mt-4 flex items-center flex-wrap gap-2 max-h-[4rem] overflow-hidden">
        {tags &&
          tags
            .filter((str) => str !== "")
            .sort((a, b) => a.length - b.length)
            .map((tagName, index) => (
              <ProjectItemTag key={index} tagName={tagName} />
            ))}
      </div>
    </div>
  );
}

export default ProjectListItem;
