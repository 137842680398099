import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";

function SearchBar({ closeMobileMenu }) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [searchKeyword, setSearchKeyword] = useState(
    searchParams.get("search") || ""
  );

  const goToSearchResult = () => {
    if (!searchKeyword) {
      navigate("/projects");
      return;
    }
    navigate(`/projects?search=${encodeURIComponent(searchKeyword)}`);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      closeMobileMenu();
      goToSearchResult();
    }
  };

  useEffect(() => {
    setSearchKeyword(searchParams.get("search") || "");
  }, [searchParams]);

  return (
    <div className="relative">
      <div className="h-full absolute left-2 flex items-center">
        <IoSearchOutline className="text-stone-800" size={24} />
      </div>
      <input
        className="py-1 pl-10 pr-2 rounded-lg mx-auto w-full text-stone-800 text-base focus:outline-none bg-stone-50"
        type="text"
        placeholder="Search Projects..."
        onKeyDown={(e) => handleKeyDown(e)}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;
