import React from "react";
import Herosection from "../Components/Herosection/Herosection";
import PreviewProjects from "../Components/PreviewProjects/PreviewProjects";
import Testimonial from "../Components/Testimonial/Testimonial";
import useGetHomepageInfo from "../hooks/useGetHomepageInfo";
import { useQueryClient } from "@tanstack/react-query";
import PreviewProjectsMobile from "../Components/PreviewProjectsMobile";

const Home = () => {
  const queryClient = useQueryClient();
  const { isLoadingHomepageInfo } = useGetHomepageInfo();
  if (!isLoadingHomepageInfo) {
    queryClient.prefetchQuery({
      queryKey: ["allProjects"],
      queryFn: async () => {
        const res = await fetch("/api/projects/route");
        const data = await res.json();
        return data;
      },
    });
  }
  return (
    <div>
      <Herosection />
      <PreviewProjectsMobile />
      <PreviewProjects />
      <Testimonial />
    </div>
  );
};

export default Home;
