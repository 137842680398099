import React from "react";
import "./AboutContent.css";

const AboutContent = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-info">
          <div className="about-us">About Us</div>
          <div className="about-content">
            <p>
              A capstone is an end-of-program applied research project where
              students will spend twenty hours per week, for fifteen weeks,
              investigating a research problem alongside an industry
              stakeholder. Most research capstone projects are related to
              machine learning, computer vision, networking, HCI, cloud
              computing, AI, NLP, speech recognition, or DevOps.
            </p>
            <br />
            <p>
              Depending on the problem, the project scope will include a
              literature review of related work, identification of methodologies
              to solve the problem, an exploratory set of experiments with
              results, a final analysis, and future work. Students will work in
              teams of three or four. Stakeholders can meet with students
              virtually or in person, typically every two weeks.
            </p>
          </div>
        </div>
        <div className="button-container">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfMd_HpF0b5FJdnfrDM-uHxrdRMXTNv9YSfWxuSrCAmcT0ezA/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="about-btn">PROPOSE PROJECTS</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
