function NEULogo() {
  return (
    <div className="w-12 h-12 flex items-center justify-center text-white bg-red-600 transition-colors duration-300 group-hover:bg-red-700 focus:outline-none">
      <svg className="w-7 h-7 mr-1 mb-1">
        <path
          d="M28 11c0-4 1.3-5.3 4-6.3V4h-8.8v.7c2.7 1 4 2.3 4 6.3v11.7L8.6 4.9C7.8 4.1 7.6 4 7 4H0v.7c1.5.2 2.5.6 4.7 2.7l.8.8v13.3c0 4-1.3 5.3-4 6.3v.7h8.8v-.7c-2.7-1-4-2.3-4-6.3V8.8l21.1 19.8h.6V11z"
          fill="#fff"
        ></path>
      </svg>
    </div>
  );
}

export default NEULogo;
