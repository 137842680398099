import { useParams } from "react-router-dom";
import { useState } from "react";
import useGetProjectById from "../hooks/useGetProjectById";
import "./SingleProjectDetails.css";
import { Spinner } from "@nextui-org/react";

function getYouTubeEmbedUrl(url) {
  if (!url) return null;
  let videoId;

  if (url?.includes("/embed/")) {
    return url;
  }

  if (url?.includes("youtu.be")) {
    const urlObj = new URL(url);
    videoId = urlObj?.pathname?.slice(1);
  } else if (url?.includes("youtube.com")) {
    const urlObj = new URL(url);
    videoId = urlObj?.searchParams.get("v");
  }

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  return null;
}

function Modal({ isOpen, onClose, imgUrl }) {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <img src={imgUrl} alt="Enlarged project poster" onClick={onClose} />
      </div>
    </div>
  );
}

function SingleProjectDetails() {
  const { projectId } = useParams();
  const { project, isLoadingProject } = useGetProjectById({ projectId });
  const [isModalOpen, setModalOpen] = useState(false);

  if (isLoadingProject)
    return (
      <div className="w-full h-[50vh] flex items-center justify-center">
        <Spinner size="lg" />
        <p className="ml-4">Loading Data...</p>
      </div>
    );
  if (!project) return <div>No project found</div>;

  const youtubeEmbedUrl = getYouTubeEmbedUrl(project.video);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "visible" : "hidden";
  };

  return (
    <div>
      <div className="single-project-title-container">
        <h1 className="single-project-title">{project.title || ""}</h1>
        <div className="single-project-tags">
          {project.tags &&
            project.tags
              .filter((str) => str !== "")
              .map((tag, index) => (
                <p className="single-tag" key={index}>
                  #{tag}
                </p>
              ))}
        </div>
      </div>
      <div className="single-project-container">
        <div className="single-project-texts-sections">
          <p className="single-project-section">STUDENTS</p>
          <div className="single-project-list">
            {project.students &&
              project.students.map((student, index) => (
                <p key={index} className="single-project-info">
                  {student || ""}
                </p>
              ))}
          </div>

          <p className="single-project-section">INSTRUCTOR</p>
          <div className="single-project-list">
            {project.instructors &&
              project.instructors.map((instructor, index) => (
                <p key={index} className="single-project-info">
                  {instructor || ""}
                </p>
              ))}
          </div>

          <p className="single-project-section">MENTOR</p>
          <div className="single-project-list">
            {project.mentor &&
              project.mentor.map((mentor, index) => (
                <p key={index} className="single-project-info">
                  {mentor || ""}
                </p>
              ))}
          </div>

          <p className="single-project-section">PARTNERS</p>
          <div className="single-project-list">
            {project.partner &&
              project.partner.map((partner, index) => (
                <p key={index} className="single-project-info">
                  {partner || ""}
                </p>
              ))}
          </div>

          <p className="single-project-section">SEMESTER</p>
          <p className="single-project-info">{project.semesters || ""}</p>

          <p className="single-project-section">PROJECT DESCRIPTION</p>
          <p className="single-project-info">{project.projectSummary || ""}</p>
        </div>
        <div className="single-project-image-video-section">
          <p className="single-project-section">POSTER</p>
          {project.poster && project.poster.asset && (
            <img
              className="single-project-poster"
              src={project.poster.asset.url || ""}
              alt="Project poster"
              onClick={toggleModal}
            />
          )}
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={toggleModal}
              imgUrl={project.poster?.asset?.url || ""}
            />
          )}
          <p className="single-project-section">VIDEO</p>
          <div className="single-project-video">
            {project.video && youtubeEmbedUrl && (
              <iframe
                src={youtubeEmbedUrl || ""}
                title="Project Video"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleProjectDetails;
