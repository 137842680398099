import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";

import SearchBar from "../SearchBar";
import NEULogo from "../NEULogo";
import NavBarMenuMobile from "../NavBarMenuMobile";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar relative">
      <div className="group" onClick={() => navigate("/")}>
        <div className="navbar-left cursor-pointer">
          <NEULogo />
          <h1 className="navbar-title group-hover:text-stone-300 transition-all duration-300">
            Vancouver Khoury Capstone Projects
          </h1>
        </div>
      </div>
      <NavBarMenuMobile />
      <ul className="navbar-menu">
        <li>
          <SearchBar />
        </li>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/projects"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            About
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
