function ListPageLayout({ title, children }) {
  return (
    <div className="md:p-10 py-4 px-2">
      <div className="flex place-content-between w-[90%] mx-auto md:px-2">
        <h1 className="text-start text-xl md:text-4xl md:mb-6 mb-2">{title}</h1>
      </div>
      <div className="border-t-2 border-stone-300 mb-4 w-[90vw] mx-auto"></div>
      {children}
    </div>
  );
}

export default ListPageLayout;
