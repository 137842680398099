import useGetProjects from "./useGetProjects";

export default function useGetFilterFields() {
  const { projects, isLoadingProjects } = useGetProjects();

  const filterData = projects?.map((project) => ({
    semesters: project.semesters,
    instructors: project.instructors,
    partners: project.partner,
    tags: project.tags,
  }));

  return { filterData, isLoadingFilterData: isLoadingProjects };
}
